@media (--mobile) {
  .wrapper:nth-child(1) .title,
  .wrapper:nth-child(2) .title,
  .wrapper:nth-child(1) .description {
    width: 7rem;
  }
}

@media screen and (max-width: 1280px) {
  .title {
    font-size: var(--typography-heading-6-font-size);
    font-weight: var(--typography-heading-6-font-weight);
    line-height: var(--typography-heading-6-line-height);
    letter-spacing: var(--typography-heading-6-letter-spacing);
  }
}

.linkText {
  margin-top: 15px;
  color: #6b7177;
}
